// Settings.tsx
import './Settings.css';

const Settings = () => {
  return (
    <div className="settings">
      Je suis la page head
    </div>
  );
};

export default Settings;